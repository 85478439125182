@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-text-medium {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.shadow-text-strong {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}
